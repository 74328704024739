import React, { useState, useEffect } from "react";
import SwiperCore, { Navigation, Pagination, Controller, Thumbs } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import "../assets/styles/components/slider-section.scss";
import { connect } from "react-redux";
import propTypes from "prop-types";
import {showProductImage} from "../store/Data/actions/appActions";

SwiperCore.use([Navigation, Pagination, Controller, Thumbs]);

const SliderSection = (props) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  // , setSlider
  const [shopTable] = useState(false);

  const slides = props.order?.images?.map((item, i) => {
    return (
      <SwiperSlide key={`slide-${i}`}>
        <img src={item} alt={`slider ${i}`} onClick={() => props.showProductImageAction(item, true)}/>
      </SwiperSlide>
    )
  });

  const thumbs = props.order?.images?.map((item, i) => {
    return (
      <SwiperSlide key={`thumb-${i}`}>
      <img src={item} alt={`Thumbnail ${i}`}/>
    </SwiperSlide>)
  });

   useEffect(() => {
     // thumbsSwiper.swiper.update()
   }, [props.order, props.order.images, shopTable])
  return (
    <section className="slider-section">
      <div className="slider-title">
        <div className="left">
          <p>
            Заказ №{props.order.id} от{" "}
            {new Date(props.order.date_creation).toLocaleDateString()}
          </p>
          <span>
            {/*<h2>Действие 1 из 5.</h2>*/}
            <p>
              В {new Date(props.order.date_creation).toLocaleTimeString()}.
            </p>
          </span>
        </div>
        {/*<div className="right">*/}
        {/*  <button onClick={() => setSlider((shopTable) => !shopTable)}>*/}
        {/*    Показать стол покупки*/}
        {/*  </button>*/}
        {/*  <button*/}
        {/*    onClick={() => console.log({ thumbsSwiper, setThumbsSwiper })}*/}
        {/*  >*/}
        {/*    {"Пред"}*/}
        {/*  </button>*/}
        {/*  <button>{"След"}</button>*/}
        {/*</div>*/}
      </div>
          <div
            className="actions-swiper"
          >
            {props.order?.images?.[0] === undefined && (
              <div>
                Изображения отсутсвуют
              </div>
            )}
            {props.order?.images?.[0] !== undefined && (
              <>
            <Swiper
              id="main"
              thumbs={{ swiper: thumbsSwiper }}
              navigation
              pagination
              spaceBetween={0}
              slidesPerView={1}
            >
              {slides}
            </Swiper>

            <Swiper
              id="thumbs"
              watchOverflow
              spaceBetween={0}
              slidesPerView={3}
              slidesPerColumn={3}
              onSwiper={setThumbsSwiper}
            >
              {thumbs}
            </Swiper>
              </>
            )}
          </div>


    </section>
  );
};
SliderSection.propTypes = {
  order: propTypes.object,
  showProductImageAction: propTypes.func,
};
const mapStateToProps = (state) => ({
  order: state.appReducer.order,
});

const mapDispatchToProps = (dispatch) => ({
  showProductImageAction: (id, fromSlider) => {
    dispatch(showProductImage(id, fromSlider));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SliderSection)
