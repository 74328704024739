import React from "react";
import {connect} from "react-redux";
import {loadAllCostumers} from "../store/Data/actions/appActions";
import propTypes from "prop-types";
import Layout from "../layout/Layout";

export default (Component) => {
  class Authenticated extends React.Component {
    async componentDidMount() {
      const activeOrder = 1
      await this.props.loadAllOrderAction(activeOrder)
    }

    render() {
      return (
        <Layout>
          <Component {...this.props} />
        </Layout>
      );
      // : <Redirect to={{ pathname: '/login' }} />;
    }
  }
  Authenticated.propTypes = {
    loadAllOrderAction: propTypes.func,
  };
  const mapDispatchToProps = (dispatch) => ({
    loadAllOrderAction: (orderState) => {
      dispatch(loadAllCostumers(orderState));
    },
  });
  return connect(null, mapDispatchToProps)(Authenticated);
};

