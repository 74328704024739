import requestApi from "../../../config/requestApi";
import {
  GET_ORDERS,
  UPDATE_ORDERS,
  GET_ORDER,
  GET_ORDERS_ARCHIVE,
  GET_PRODUCTS,
  CREATE_ERR,
  CLOSE_ERR,
  SET_ACTIVE_ORDER,
  PUT_PRODUCT,
  START_TASK,
  END_TASK,
  DELETE_PRODUCT,
  SHOW_PRODUCT_IMAGE,
  SET_OPEN_USER_INFO_TAB,
  GET_BUYER,
} from "../../types";

export const getBuyer = (id) => (dispatch) => {
  dispatch(
    requestApi({
      url: `/user`,
      method: "get",
      params: {
        id
      },
      body: {},
      successType: GET_BUYER,
    })
  );
};

export const setIsOpenUserTab = (flag) => ({
  type: SET_OPEN_USER_INFO_TAB,
  payload: flag,
});

export const createErr = (content) => ({
  type: CREATE_ERR,
  payload: content,
});

export const showProductImage = (id, fromSlider) => ({
  type: SHOW_PRODUCT_IMAGE,
  payload: {id, fromSlider},
});

export const closeError = (i) => ({
  type: CLOSE_ERR,
  payload: i,
});
export const setActiveOrder = (id) => ({
  type: SET_ACTIVE_ORDER,
  payload: id,
});

export const loadOrder = (id) => (dispatch) => {
  dispatch(
    requestApi({
      url: `/order`,
      method: "get",
      params: {
        id: id
      },
      startType: START_TASK,
      successType: GET_ORDER,
      endType: END_TASK,
    })
  );
};
export const payment = (id) => (dispatch) => {
  dispatch(
    requestApi({
      url: `/payment/pay`,
      method: "post",
      body: {
        id: id
      },
    })
  );
};
export const loadAllCostumers = (orderState) => (dispatch) => {
  dispatch(
    requestApi({
      url: `/order/all`,
      method: "get",
      params: {
        active: orderState,
      },
      successType: orderState === 1 ? GET_ORDERS : GET_ORDERS_ARCHIVE,
    })
  );
};
export const hotOrders = (orderState) => (dispatch) => {
  dispatch(
    requestApi({
      url: `/order/all`,
      method: "get",
      params: {
        active: orderState,
      },
      successType: UPDATE_ORDERS,
    })
  );
};


export const loadProducts = (name, id) => (dispatch) => {
  dispatch(
    requestApi({
      url: `/products`,
      method: "get",
      params: {
        name: name,
        order_id: id
      },
      body: {},
      successType: GET_PRODUCTS,
    })
  );
};

export const endTaskLoad = () => ({
  type: END_TASK,
});
// eslint-disable-next-line no-unused-vars
export const putToCart = (products) => (dispatch) => {
  dispatch(
    requestApi({
      url: `/order`,
      method: "put",
      params: {
        id: products.order.id,
      },
      body: {
        cart: [...products.cart],
      },
    })
  );
};

export const changeCart = (product) => ({
  type: PUT_PRODUCT,
  payload: product.item,
});

export const deleteProduct = (product) => ({
  type: DELETE_PRODUCT,
  payload: product,
});
