import React from "react";
import {connect} from "react-redux";
import '../assets/styles/components/image-product.scss'
import { showProductImage} from "../store/Data/actions/appActions";

const ProductImage = (props) => {
  if(props.showingProductImage !== null) {
   return (
     <div className='product-image' onClick={() => props.showProductImageAction(null)}>
       <div className='image'>
         <img src = {props.showingProductImage} />
       </div>
     </div>
   )
  }
  return (
    ''
  )
}
const mapStateToProps = (state) => ({
  showingProductImage: state.appReducer.showingProductImage,
});
const mapDispatchToProps = (dispatch) => ({
  showProductImageAction: (id) => {
    dispatch(showProductImage(id));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(ProductImage)
