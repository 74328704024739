import React, {useEffect} from 'react';
import Header from "../components/Header";
import propTypes from "prop-types";
import {hotOrders, setActiveOrder} from "../store/Data/actions/appActions";
import {connect} from "react-redux";
import Preloader from "../components/Preloader";

const Layout = (props) => {

  useEffect(() => {
    const hotUpdate = setInterval(() => {
      props.loadAllCostumersAction()
    }, 10000)
    return () => {
      clearInterval(hotUpdate)
    }
  }, [])
  return (
    <main>
      {props.loader && (
          <Preloader />
        )
      }
        <Header />
        <div className='page-container'>
          {props.children}
        </div>
    </main>
  )
}

Layout.propTypes = {
  loader: propTypes.bool,
  children: propTypes.object,
  loadAllCostumersAction: propTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  setActiveOrderAction: (id) => {
    dispatch(setActiveOrder(id));
  },
  loadAllCostumersAction:() => {
    dispatch(hotOrders())
  },
});
const mapStateToProps = (state) => ({
  loader: state.appReducer.loader,
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
