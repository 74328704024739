import axios from "axios";
import Cookies from "js-cookie";
import { to } from "await-to-js";
import { API_URL } from "./config";

const instance = axios.create({
  baseURL: API_URL,
});

instance.interceptors.request.use(
  function (config) {
    config.headers.Authorization = `Bearer ${Cookies.getJSON().accessToken}`;

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const res = error.response;
    const originalRequest = error.config;
    const { refreshToken } = Cookies.getJSON();

    if (res.status !== 401) {
      return;
    }

    if (refreshToken && !originalRequest._retry) {
      originalRequest._retry = true;

      const [err, access] = await to(
        exchangeRefreshTokenToAccess(refreshToken)
      );
      if (err) {
        window.location = "/";
        return;
      }

      Cookies.set("accessToken", access);
      // Cookies.set("refreshToken", newRefreshToken);
    } else {
      originalRequest._retry = false;
      window.location = "/";
      return Promise.reject(error);
    }
  }
);

async function exchangeRefreshTokenToAccess(refreshToken) {
  const res = await instance.post("user/token/refresh", {
    refresh: refreshToken,
  });
  const { access } = res.data;
  return access;
}

export default instance;
