import React, {useRef, useState} from "react";
import "../assets/styles/components/products-handler.scss";
import ThreeColTable from "./ThreeColTable";
import propTypes from "prop-types";
import { connect } from "react-redux";
import {endTaskLoad, loadProducts} from "../store/Data/actions/appActions";
import {debounce} from "lodash";

const ProductsHandler = (props) => {
  const [userQuery, setUserQuery] = useState("");
  const delayedQuery = useRef(debounce((q, activeOrderId) => props.loadProductsAction(q, activeOrderId), 500)).current;

  const onChange = async (val) => {
    setUserQuery(val, props.activeOrderId);
    if(val.length >= 2) delayedQuery(val, props.activeOrderId);
    if (!val) delayedQuery(val, props.activeOrderId)
  };

    return (
      <section className="products-handler">
        <div className="header">
          <div className="tabs">
            <span>Все товары</span>
            {/*<span>В зоне действия</span>*/}
            {/*<span>Распознанные</span>*/}
          </div>
          <input
            onChange={(e) => onChange(e.target.value)}
            value={userQuery}
            placeholder={'Введите название товара'}
          />
        </div>
        <ThreeColTable colors={['pale-green', 'white']} sectionShopCard={false} items={props.currentProducts} />
      </section>
    );
}

ProductsHandler.propTypes = {
  activeOrderId: propTypes.number,
  loadProductsAction: propTypes.func,
  endTaskLoadAction: propTypes.func,
  currentProducts: propTypes.array,
  allProducts: propTypes.array,
};
const mapStateToProps = (state) => ({
  currentProducts: state.appReducer.currentProducts,
  allProducts: state.appReducer.products,
  activeOrderId: state.appReducer.activeOrderId
});

const mapDispatchToProps = (dispatch) => ({
  loadProductsAction: (name, id) => {
    dispatch(loadProducts(name, id));
  },
  endTaskLoadAction: () => {
    dispatch(endTaskLoad());
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(ProductsHandler);
