import React from "react";
import propTypes from "prop-types";
import "../assets/styles/layouts/login-page.scss";
import Input from "../components/Input";
import { API_URL } from "../config/config";
import axios from "axios";
import { createErr } from "../store/Data/actions/appActions";
import { connect } from "react-redux";
import Cookies from "js-cookie";

class LoginPage extends React.Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      passwordValid: null,
      emailValid: null,
      err: 0,
    };
    this.onChangeInputHandler = this.onChangeInputHandler.bind(this);
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
  }
  componentDidMount() {
    // Cookies.set('accessToken', 0)
  }

  onChangeInputHandler(value, type) {
    this.setState({
      [type]: value,
    });
  }

  async onSubmitHandler() {
    const self = this
    const { email, password } = this.state;
    await axios
      .post(`${API_URL}/user/token`, {
        email: email,
        password: password,
      })
      .then((res) => {
        const { data } = res;
        Cookies.set("accessToken", data.access);
        Cookies.set("refreshToken", data.refresh);
        this.props.history.push("/orders");
      })
      .catch(async (err) => {
        if (err.message === "Network Error")
          return self.props.createError("Ошибка подключения, проверьте соединение");
        if (err.response.status === 400) {
          await this.setState({ err: this.state.err + 1 });
          self.props.createError("Вы ввели неверные данные для авторизации!");
        }
      });
  }

  render() {
    return (
      <section className="login-page">
        <div className="form-container">
          <div className="title">
            <h1>Авторизуйтесь</h1>
            <p>Для доступа в панель управления</p>
          </div>
          <div
            className="form rounded-corner"
            onSubmit={(e) => this.onSubmitHandler(e)}
          >
            <Input
              value={this.state.email}
              label="E-mail"
              type="email"
              onChangeHandle={(value, type) =>
                this.onChangeInputHandler(value, type)
              }
            />
            <Input
              value={this.state.password}
              label="Пароль"
              type="password"
              onChangeHandle={(value, type) =>
                this.onChangeInputHandler(value, type)
              }
            />
            <button onClick={this.onSubmitHandler}>ВОЙТИ</button>
          </div>
        </div>
      </section>
    );
  }
}

LoginPage.propTypes = {
  history: propTypes.object,
  createError: propTypes.func,
};
const mapDispatchToProps = (dispatch) => ({
  createError: (content) => {
    dispatch(createErr(content));
  },
});
export default connect(null, mapDispatchToProps)(LoginPage);
