import React, {useEffect} from "react";
import "../assets/styles/layouts/main-page.scss";
import SliderSection from "../components/SliderSection";
import ShopCard from "../components/ShopCard";
import { connect } from "react-redux";
import propTypes from "prop-types";
import {
  createErr,
  loadOrder,
  loadProducts,
  endTaskLoad,
  setIsOpenUserTab,
} from "../store/Data/actions/appActions";
import ProductsHandler from "../components/ProductsHandler";
import {Route, Switch, useRouteMatch, useParams } from "react-router-dom";
import ProductImage from "../components/productImage";
import Payment from "../components/payment";

const OrderPage = (props) => {

  let { id } = useParams();
  let { url } = useRouteMatch();

  useEffect(() => {
    props.loadProductsAction("", Number(id));
    props.loadOrderAction(Number(id));
    props.setIsOpenUserTabAction(true);
  }, []);

  return (
    <div className="costumer-page">
      <div className="body">
        <div className="left-side">
          <SliderSection />
          <ShopCard />
        </div>
        <div className="right-side">
          <ProductsHandler />
          <Payment id={props.order.id} />
        </div>
        <ProductImage />
      </div>
      <Switch>
        <Route path={`${url}/:id`}>
          <OrderPage />
        </Route>
      </Switch>
    </div>
  );
}

OrderPage.propTypes = {
  history: propTypes.object,
  order: propTypes.object,
  createError: propTypes.func,
  loadOrderAction: propTypes.func,
  loadProductsAction: propTypes.func,
  endTaskLoadAction: propTypes.func,
  setIsOpenUserTabAction: propTypes.func,
};

const mapStateToProps = (state) => ({
  order: state.appReducer.order,
  buyer: state.appReducer.buyer,
});

const mapDispatchToProps = (dispatch) => ({
  setIsOpenUserTabAction: (flag) => {
    dispatch(setIsOpenUserTab(flag));
  },
  createError: (content) => {
    dispatch(createErr(content));
  },
  loadOrderAction: async (id) => {
    dispatch(await loadOrder(id));
  },
  loadProductsAction: async (name, orderiId) => {
    dispatch(await loadProducts(name, orderiId));
  },
  endTaskLoadAction: () => {
    dispatch(endTaskLoad());
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(OrderPage);
