import React, { useCallback, useEffect} from "react";
import propTypes from "prop-types";
import { connect } from "react-redux";
import { putToCart, changeCart, deleteProduct} from "../store/Data/actions/appActions";
import ThreeColTableItem from "./TreeColTableItem";
import { debounce } from 'lodash';


const ThreeColTable = (props) => {
  const {
    items,
    sectionShopCard,
    order,
    cart,
    deleteProductAction,
    changeCartAction,
    colors
  } = props;

  function addToCard(item){
    changeCartAction(item)
  }
  function deleteProductCart(item) {
    deleteProductAction(item)
  }
  const updateCard = useCallback(
    debounce(() => props.putToCartAction({cart, order}),
        0,
      {trailing:true}
      ), [order, cart.length]);

  useEffect(() => {
      sectionShopCard ? updateCard() : ''
  }, [cart])

  return (
    <div className="table">
      <div className="table-header">
        <p>Наименование</p>
        <p>Цена</p>
        <p>Кол-во</p>
        <p>Сумма</p>
      </div>
      <div className="table-body">
        {items.map((item, i) => {
            if (i <= 100) {
             return <ThreeColTableItem
                color={i % 2 === 0 ? colors[0] : colors[1]}
                sectionShopCard = {sectionShopCard}
                key={sectionShopCard ? `tableItemCart${i}` : `tableItemCart${i}`}
                item={sectionShopCard ? {...item.product, amount: item.amount} : item}
                callBackAction={sectionShopCard ? () => deleteProductCart(item) : () => addToCard({item})}
              />
            }
          }
        )}
      </div>
    </div>
  );
};

ThreeColTable.propTypes = {
  colors: propTypes.array,
  sectionShopCard: propTypes.bool,
  items: propTypes.array,
  putToCartAction: propTypes.func,
  deleteProductAction: propTypes.func,
  cart: propTypes.array,
  changeCartAction: propTypes.func,
  order: propTypes.object,
};

const mapStateToProps = (state) => ({
  order: state.appReducer.order,
  cart: state.appReducer.cart,
});

const mapDispatchToProps = (dispatch) => ({
  putToCartAction: (products) => {
    dispatch(putToCart(products));
  },
  changeCartAction: (product) => {
    dispatch(changeCart(product));
  },
  deleteProductAction: (product) => {
    dispatch(deleteProduct(product));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ThreeColTable);
