import React, {useEffect, useState} from "react";
import "../assets/styles/components/shop-card.scss";
import { connect } from "react-redux";
import propTypes from "prop-types";
import ThreeColTable from "./ThreeColTable";

const ShopCard = (props) => {
  const { cart } = props;
  const [summ, setSumm] = useState(0)
  function getCartSumm () {
    let summ = 0
    if(cart.length > 0) {
      cart.forEach(product => {
        summ += product.amount * product.product.price
      })
      setSumm(`${(summ/100).toFixed(0)} руб. ${(summ % 100)} коп.`)
    }
  }
   useEffect(() => {
     getCartSumm()
   }, [cart])
    return (
      <section className="shop-card">
        <div className="shop-card-title">
          {/*<p>Последнее обновление 01.01.2020 в 00:05:30</p>*/}
          <div className="summ">
            <h2>КОРЗИНА </h2>
            <p>(товаров на сумму: {summ})</p></div>
        </div>
        <ThreeColTable colors={['pale-green','green' ]} sectionShopCard items={cart} />
      </section>
    );
}

ShopCard.propTypes = {
  order: propTypes.object,
  cart: propTypes.array,
};

const mapStateToProps = (state) => ({
  order: state.appReducer.order,
  cart: state.appReducer.cart,
});

export default connect(mapStateToProps, null)(ShopCard);
