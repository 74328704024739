import React, {useEffect} from "react";
import '../assets/styles/layouts/orders.scss';
import {connect} from "react-redux";
import {setActiveOrder, createErr, setIsOpenUserTab} from "../store/Data/actions/appActions";
import propTypes from "prop-types";
import {Link} from 'react-router-dom';

const OrdersPage = (props) =>  {

  useEffect(() => {
    props.setIsOpenUserTabAction(false)
  },[])

  function getOrderStatus(orderStatus) {
    if(orderStatus === 0) {
      return "Требуется обработка"
    }
    if(orderStatus === 1) {
      return "Требуется обработка"
    }
    if(orderStatus === 2) {
      return "В обработке"
    }
    if(orderStatus === 3) {
      return "Обработан"
    }
    return orderStatus === 0 ? 'Обрабатывается' : 'Брошен'
  }

  return (
    <div className="orders">
      {props.orders.length >= 1 ? (
     <div className="order-table">
       <div className="order-table-header order-table-header-new">
         <span>№</span>
         <span>Дата / Время</span>
         <span>Точка</span>
         <span>Товаров</span>
         <span>Сумма</span>
         <span>Текущий статус</span>
       </div>
       <div className="order-table-body">
         {props.orders.map((order, i)=> {
           return (
             <Link className="link-router" key={`order${i}`} to={`/orders/${order.id}`}>
               <div className={`order-table-item order-table-item-new rounded-corner ${i % 2 === 0 ? 'pale-green' : 'green'}`}>
                 <span>№{order.id}</span>
                 <span>{new Date(order.date_creation).toLocaleString()}</span>
                 <span>{order.shop}</span>
                 <span>{order.cart.length}</span>
                 <span>{order.total}</span>
                 <span>{getOrderStatus(order.status)}</span>
               </div>
             </Link>
           )
         })}
       </div>
     </div> ) : (
       <p>Нет заказов</p>
        )
      }
  </div>
  )
};

OrdersPage.propTypes = {
  orders: propTypes.array,
  activeOrderId: propTypes.number,
  setActiveOrderAction: propTypes.func,
  createError: propTypes.func,
  setIsOpenUserTabAction: propTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  setActiveOrderAction: (id) => {
    dispatch(setActiveOrder(id));
  },
  createError: (message) => {
    dispatch(createErr(message))
  },
  setIsOpenUserTabAction: (flag) => {
    dispatch(setIsOpenUserTab(flag));
  }
});
const mapStateToProps = (state) => ({
  orders: state.appReducer.orders,
  activeOrderId: state.appReducer.activeOrderId,
});

export default connect(mapStateToProps, mapDispatchToProps)(OrdersPage)
