import React from "react";
import ReactDOM from "react-dom";
import App from "./App.jsx";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store/store";
import Authenticated from "./config/Authenticated";
import {
  OrdersPage,
  ArchivePage,
  LoginPage,
  OrderPage,
  BuyerInfo,
} from './routes';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App>
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={LoginPage} />
            <Route exact path="/orders" component={Authenticated(OrdersPage)} />
            <Route exact path="/archive" component={Authenticated(ArchivePage)} />
            <Route path="/orders/:id" component={Authenticated(OrderPage)} />
            <Route exact path="/order/:orderId/buyer/:id" component={Authenticated(BuyerInfo)} />
            <Route render={() => <div>Miss</div>} />
          </Switch>
        </BrowserRouter>
      </App>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
