import axios from "./axios";
import { to } from "await-to-js";
import {CREATE_ERR} from "../store/types";

export default function requestApi(options) {
  return async (dispatch) => {
    if (options.startType) dispatch({ type: options.startType });
    const [err, res] = await to(
      axios({
        method: options.method,
        url: options.url,
        params: options.params,
        data: options.body
      })
    );

    if (err) {
      dispatch({type: CREATE_ERR, payload: 'Ошибочка'})
    }
    if (options.successType) dispatch({ type: options.successType, payload: res.data });
    if (options.endType) dispatch({type: options.endType});
  };
}
