export const GET_ORDER = "GET_ORDER";
export const GET_ORDERS = "GET_ORDERS";
export const GET_ORDERS_ARCHIVE = "GET_ORDERS_ARCHIVE";
export const UPDATE_ORDERS = "UPDATE_ORDERS";
export const GET_PRODUCTS = "GET_PRODUCTS";
export const CLOSE_ERR = "CLOSE_ERR";
export const CREATE_ERR = "CREATE_ERR";
export const SET_ACTIVE_ORDER = "SET_ACTIVE_ORDER";
export const PUT_PRODUCT = "PUT_PRODUCT";
export const END_TASK = "END_TASK";
export const START_TASK = "START_TASK";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const SHOW_PRODUCT_IMAGE = "SHOW_PRODUCT_IMAGE";
export const SET_OPEN_USER_INFO_TAB = "SET_OPEN_USER_INFO_TAB";
export const GET_BUYER = "GET_BUYER";
