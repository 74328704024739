import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import propTypes from "prop-types";
import { loadAllCostumers, setIsOpenUserTab } from "../store/Data/actions/appActions";
import { connect } from "react-redux";

const ArchivePage = (props) => {

  useEffect(() => {
    const archiveState = 0;
    props.loadAllCostumersAction(archiveState);
    props.setIsOpenUserTabAction(false);
  }, []);

  function getOrderStatus(orderStatus) {
    if(orderStatus === 0) {
      return "Требуется обработка"
    }
    if(orderStatus === 1) {
      return "Требуется обработка"
    }
    if(orderStatus === 2) {
      return "В обработке"
    }
    if(orderStatus === 3) {
      return "Обработан"
    }
    return orderStatus === 0 ? 'Обрабатывается' : 'Брошен'
  }

  return (
    <div className="orders">
      {props.archiveOrders.length >= 1 ? (
        <div className="order-table">
          <div className="order-table-header order-table-header-archive">
            <span>№ Заказа</span>
            <span>Дата / Время</span>
            <span>Точка</span>
            <span>Товаров в корзине</span>
            <span>Сумма</span>
            <span>Текущий статус</span>
            <span>Результат</span>
          </div>
          <div className="order-table-body">
            {props.archiveOrders.map((order, i) => {
              return (
                <Link
                  className="link-router"
                  key={`order${i}`}
                  to={`/orders/${order.id}`}
                >
                  <div
                    className={`order-table-item order-table-item-archive rounded-corner ${
                      i % 2 === 0 ? "pale-green" : "green"
                    }`}
                  >
                    <span>№{order.id}</span>
                    <span>{new Date(order.date_creation).toLocaleString()}</span>
                    <span>{order.shop}</span>
                    <span>{order.cart.length}</span>
                    <span>{order.total}</span>
                    <span>{getOrderStatus(order.status)}</span>
                    <span>Результат</span>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      ) : (
        <p>Нет Заказов</p>
      )}
    </div>
  );
};

ArchivePage.propTypes = {
  archiveOrders: propTypes.array,
  activeOrderId: propTypes.number,
  loadAllCostumersAction: propTypes.func,
  setIsOpenUserTabAction: propTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  loadAllCostumersAction: (orderState) => {
    dispatch(loadAllCostumers(orderState));
  },
  setIsOpenUserTabAction: (flag) => {
    dispatch(setIsOpenUserTab(flag));
  }
});
const mapStateToProps = (state) => ({
  archiveOrders: state.appReducer.archiveOrders,
});

export default connect(mapStateToProps, mapDispatchToProps)(ArchivePage);
