import React, {useEffect} from "react";
import closeBtn from "../assets/images/closeBtn.svg";
import "../assets/styles/components/error-handler.scss";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { closeError } from "../store/Data/actions/appActions";

const ErrorHandler = (props) => {
  useEffect(() => {
      setTimeout(() => {
        props.closeErr(0)
      },3000)
  }, [props.errors])
  return (
    <section className="error-handler">
      {props.errors.map((item, i) => (
        <div className="item rounded-corner" key={`err${i}`}>
          <span className="close-btn" onClick={() => props.closeErr(i)}>
            <img src={closeBtn} alt={"close"} />
          </span>
          <p>{item}</p>
        </div>
      ))}
    </section>
  );
};

ErrorHandler.propTypes = {
  errors: PropTypes.array,
  closeErr: PropTypes.func,
};

const mapStateToProps = (state) => ({
  errors: state.appReducer.errors,
});

const mapDispatchToProps = (dispatch) => ({
  closeErr: (i) => {
    dispatch(closeError(i));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(ErrorHandler);
