import {
  GET_PRODUCTS,
  PUT_PRODUCT,
  GET_ORDERS,
  CLOSE_ERR,
  CREATE_ERR,
  GET_ORDER,
  SET_ACTIVE_ORDER,
  GET_ORDERS_ARCHIVE,
  END_TASK,
  START_TASK,
  DELETE_PRODUCT,
  SHOW_PRODUCT_IMAGE,
  UPDATE_ORDERS,
  SET_OPEN_USER_INFO_TAB,
  GET_BUYER,
} from "../../types";

const initialState = {
  errors: [],
  order: {},
  cart: [],
  orders: [],
  archiveOrders: [],
  activeOrderId: null,
  products: [],
  currentProducts: [],
  loader: false,
  showingProductImage: null,
  isShowUserInfoTab: false,
  buyer: {
    id: null,
  },
};

export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case GET_BUYER: {
      return { ...state, buyer: action.payload.user }
    }
    case SET_OPEN_USER_INFO_TAB: {
      return { ...state, isShowUserInfoTab: action.payload }
    }
    case CREATE_ERR: {
      let errors = [...state.errors];
      errors.push(action.payload);
      if (errors.length > 3) {
        errors.pop();
      }
      return { ...state, ...{ errors } };
    }
    case CLOSE_ERR: {
      let errors = [...state.errors];
      errors.splice(action.payload, 1);
      return { ...state, ...{ errors } };
    }
    case SHOW_PRODUCT_IMAGE: {
      if(action.payload.fromSlider) return {...state, showingProductImage: action.payload.id}
      if(action.payload.id === null) return {...state, showingProductImage: null}
      const product = state.products.find(item => item.id === action.payload.id)
      const photoUrl = product.photo !== '' ? product.photo : null
      return { ...state, showingProductImage: photoUrl};
    }
    case GET_PRODUCTS: {
      const productsAll = state.products.length > 0 ? [...state.products] : [...action.payload.products]
      return { ...state, products: productsAll, currentProducts: action.payload.products };
    }
    case GET_ORDER: {
      let cart = action.payload.order.cart
      return { ...state, order: action.payload.order,
        activeOrderId:  action.payload.order.id,
        cart: cart, buyer: {id: action.payload.order.user_id }
      };
    }
    case GET_ORDERS: {
      return {...state, orders: action.payload.orders};
    }
    case GET_ORDERS_ARCHIVE: {
      return { ...state, archiveOrders: action.payload.orders };
    }
    case SET_ACTIVE_ORDER: {
      return { ...state, activeOrderId: action.payload };
    }
    case END_TASK: {
      return { ...state, loader: false };
    }
    case START_TASK: {
      return { ...state, loader: true };
    }
    case PUT_PRODUCT: {
      const cart = [...state.cart];
      const product = cart.findIndex(item => item.product.id === action.payload.id)
      if(product !== -1) {
        cart[product].amount+=1
      }
      else {cart.push({
        amount: 1,
        product: {...action.payload}
      });
      }
      return { ...state, cart };
    }
    case DELETE_PRODUCT: {
      const cart = [...state.cart];
      const productIndex = cart.findIndex(item => item.product.id === action.payload.product.id)
      if(cart[productIndex].amount !== 1) {
        cart[productIndex].amount-=1
      }
      else {
        cart.splice(productIndex, 1);
      }
      return { ...state, cart };
    }
    case UPDATE_ORDERS: {
      const newOrders = action.payload;
      if (newOrders.length > 0) {
        const orders = [...state.orders];
        const errors = [...state.errors];
        errors.push("Создан новый заказ")
        return {...state, orders: [...orders, ...newOrders], ...{ errors }}
      }
      return { ...state }
    }
    default:
      return state;
  }
}
