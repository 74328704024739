import React from "react";
import "../assets/styles/components/forms.scss";
import PropTypes from "prop-types";

class Input extends React.Component {
  constructor(props) {
    super(props);
    this.onChangeFunc = this.onChangeFunc.bind(this);
  }
  onChangeFunc(e) {
    this.props.onChangeHandle(e.target.value, this.props.type);
  }
  render() {
    const { label, type, value } = this.props;
    return (
      <div className="input">
        <label>{label}</label>
        <input
          className="rounded-corner"
          value={value}
          type={type}
          onChange={(e) => this.onChangeFunc(e)}
        />
      </div>
    );
  }
}

Input.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChangeHandle: PropTypes.func.isRequired,
};

export default Input;
