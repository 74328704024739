import React from 'react';
import '../assets/styles/components/preloader.scss'


const Preloader = () => {

  return(
  <React.Fragment>
    <div className="loader">
      <div className="outer"></div>
      <div className="middle"></div>
      <div className="inner"></div>
    </div>

  </React.Fragment>)
}
export default Preloader;
