import React from 'react';
import closeButtonBlack from "../assets/images/closeButtonBlack.svg";
import magnifier from "../assets/images/magnifier.svg";
import propTypes from "prop-types";
import {connect} from "react-redux";
import {showProductImage} from "../store/Data/actions/appActions";


const ThreeColTableItem = (props) => {

  const {item, callBackAction, sectionShopCard, color, showProductImageAction} = props
  const {name, amount, price, photo} = item

  return (
    <div className={`table-item rounded-corner ${color}`}>
      <p onClick={sectionShopCard ? () => showProductImageAction(item.id) : () => callBackAction({item})}>{name}</p>
      <p>{(price / 100).toFixed(2)}</p>
      <p>{amount || 1}</p>
      <p> {amount ? (amount * price / 100).toFixed(2) : (price / 100).toFixed(2)}</p>
      {sectionShopCard && (
        <p className="table-button">
          <img src={closeButtonBlack} onClick={() => callBackAction({item})}/>
        </p>
      )}
      {photo && (
      <p className="table-button">
        <img src={magnifier} onClick={() => showProductImageAction(item.id)}/>
      </p>
      )}
    </div>
  )
}
ThreeColTableItem.propTypes = {
  color: propTypes.string,
  sectionShopCard: propTypes.bool,
  item: propTypes.object,
  callBackAction: propTypes.func,
  showProductImageAction: propTypes.func,
};
const mapDispatchToProps = (dispatch) => ({
  showProductImageAction: (id) => {
    dispatch(showProductImage(id));
  },
});
export default connect(null, mapDispatchToProps)(ThreeColTableItem);
