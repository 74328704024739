import React from "react";
import ErrorHandler from "./components/ErrorHandler";

import "./assets/styles/main.scss";

class App extends React.Component {
  render() {
    return (
      <div className="wrapper">
        {/* eslint-disable-next-line react/prop-types */}
        {this.props.children}
        <ErrorHandler />
      </div>
    );
  }
}

export default App;
