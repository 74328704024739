import React, {useEffect} from "react";
import { Link } from "react-router-dom";
import "../assets/styles/components/header.scss";
import propTypes from "prop-types";
import {connect} from "react-redux";

const Header = (props) => {

  const {isShowUserInfoTab, buyerId, orderId} = props
  useEffect(()=>{},[props.isShowUserInfoTab])

  return (
    <header>
      <nav>
        <Link className="nav-link rounded-corner" to="/orders">
          Заказы
        </Link>
        <Link className="nav-link rounded-corner" to="/archive">
          Архив
        </Link>
        {isShowUserInfoTab &&
        (
          <Link className="nav-link rounded-corner" to={`/order/:${orderId}/buyer/${buyerId}`}>
            Информация о клиенте
          </Link>
        )}
      </nav>
    </header>
  );
}


Header.propTypes = {
  isShowUserInfoTab: propTypes.bool,
  setIsOpenOrderPage: propTypes.func,
  buyerId: propTypes.number,
  orderId: propTypes.number,
};

const mapStateToProps = (state) => ({
  isShowUserInfoTab: state.appReducer.isShowUserInfoTab,
  buyerId: state.appReducer.buyer.id,
  orderId: state.appReducer.order.id,
});

export default connect(mapStateToProps)(Header);
