import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import { connect } from "react-redux";
import propTypes from "prop-types";
import { getBuyer } from "../store/Data/actions/appActions";
import '../assets/styles/components/buyer-info.scss';

const BuyerInfo = (props) => {

  const {id} = useParams();
  const [buyer, setBuyer] = useState({});

  useEffect(() => {
    props.getBuyerAction(id);
  },[])

  useEffect(() => {
    if(props.buyer.id) {
      const {
        balance,
        firstname: firstName,
        patronymic,
        secondname,
        telephone
      } = props.buyer
      setBuyer({
        balance,
        fullName: `${firstName} ${secondname} ${patronymic}`,
        telephone,
      });
    }
  }, [props.buyer])


  return(
    <div>
       <ul className={'list'}>
         <li className={'list-item'}>ФИО: {buyer.fullName}</li>
         <li className={'list-item'}>Баланс: {buyer.balance}</li>
         <li className={'list-item'}>Номер телефона: {buyer.telephone}</li>
       </ul>
    </div>
  )
}

BuyerInfo.propTypes = {
  getBuyerAction: propTypes.func,
  buyer: propTypes.object,
}

const mapStateToProps = (state) => ({
  buyer: state.appReducer.buyer,
});

const mapDispatchToProps = (dispatch) => ({
  getBuyerAction: async (id) => {
    dispatch(await getBuyer(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BuyerInfo);
