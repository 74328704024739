import React from "react";
import { payment } from "../store/Data/actions/appActions";
import { connect } from "react-redux";
import propTypes from "prop-types";
import "../assets/styles/components/payment.scss";

const Payment = (props) => {
  return (
    <div className="payment">
      {/*<div className="payment-actions">*/}
      {/*  <span>Отменить </span>*/}
      {/*  <span>Повторить </span>*/}
      {/*  <span>Очистить</span>*/}
      {/*  <span>Завершить заказ</span>*/}
      {/*</div>*/}
      <h2>ОПЛАТА</h2>
      <div className="payment-main">
        <div className="payment-status">
          <div className="payment-current">
            <h5>Текущий статус</h5>
            <p>Ожидание оплаты</p>
          </div>
          {/*<div className="payment-result">*/}
          {/*  <h5>Результат</h5>*/}
            {/*<p>Недостаточно средств</p>*/}
          {/*</div>*/}
        </div>
        <div className="payment-buttons">
          <button className="rounded-corner" onClick={() => props.paymentAction(props.activeOrderId)}>
            Запустить оплату
          </button>
          {/*<button onClick={() => props.paymentAction(props.activeOrderId)}>*/}
          {/*  Перезапустить оплату*/}
          {/*</button>*/}
          {/*<button onClick={() => props.paymentAction(props.activeOrderId)}>*/}
          {/*  Отменить оплату*/}
          {/*</button>*/}
        </div>
      </div>
    </div>
  );
};
Payment.propTypes = {
  activeOrderId: propTypes.number,
  paymentAction: propTypes.func,
};
const mapStateToProps = (state) => ({
  activeOrderId: state.appReducer.activeOrderId,
});
const mapDispatchToProps = (dispatch) => ({
  paymentAction: (id) => {
    dispatch(payment(id));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(Payment);
